import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// reactstrap components
import { Collapse, Navbar, NavItem, NavLink, Nav, Container, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { translate } from 'utils/translate';
import { connect, useDispatch } from 'react-redux';

function IndexNavbar(props) {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const location = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [language, setLanguage] = useState(props.lang);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 99 ||
        document.body.scrollTop > 99
      ) {
        setNavbarColor("navbar-transparent2");
      } else if (
        document.documentElement.scrollTop < 100 ||
        document.body.scrollTop < 100
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  useEffect(() => {
    setLanguage(props.lang === "EN" ? "English" : props.lang === "ZH_CN" ? "简体中文" : props.lang === "ZH_TW" ? "繁體中文" : "Indonesian");
  }, [props.lang]);

  const selectLanguageMobile = (item, label) => {
    setLanguage(label);
    dispatch({
      type: 'UPDATE_LANGUAGE',
      lang: item
    });
    localStorage.setItem('ymgweb-lang', item);
  }

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} color-on-scroll="100" expand="lg">
        <Container className="container nav-maxWidth py-2">
          <div className="navbar-translate">
            <div id="navbar-brand">
              <a href="/home"> 
                <img className="logoSize" src="../../assets/img/logo.webp" alt="logo" />
              </a>
            </div>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
               title="menu toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end align-items-center mr-0"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink href="/home">
                  <span className={location.pathname === "/home" ? "active-nav-txt" : "nav-txt"}>{translate(props.lang, "首页")}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/about-us">
                  <span className={location.pathname === "/about-us" ? "active-nav-txt" : "nav-txt"}>{translate(props.lang, "关于我们")}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/longterm-pass">
                  <span className={location.pathname === "/longterm-pass" ? "active-nav-txt" : "nav-txt"}>{translate(props.lang, "长期签证")}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/property">
                  <span className={location.pathname.includes("property") ? "active-nav-txt" : "nav-txt"}>{translate(props.lang, "房地产")}</span>
                </NavLink>
              </NavItem>
              <NavItem className="eduDropdown px-4 py-2 px-lg-3 py-lg-0">
                <Dropdown isOpen={dropdownOpen} toggle={toggle} className="h-100">
                  <DropdownToggle caret className="d-flex justify-content-center align-items-center h-100">
                    <span className={location.pathname.includes("education") ? "active-nav-txt px-1" : "nav-txt px-1"}>{translate(props.lang, "教育")}</span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem><a href="/education/application-flow"><span className={location.pathname.includes("application-flow") ? "active-nav-txt" : "nav-txt"}>{translate(props.lang, "申请流程")}</span></a></DropdownItem>
                    <DropdownItem><a href="/education/international-schools"><span className={location.pathname.includes("international-school") ? "active-nav-txt" : "nav-txt"}>{translate(props.lang, "国际学校")}</span></a></DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </NavItem>
              <NavItem>
                <NavLink href="/contact-us">
                  <span className={location.pathname === "/contact-us" ? "active-nav-txt" : "nav-txt"}>{translate(props.lang, "联系我们")}</span>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

const mapStateToProps = state => {
  const { i18n } = state;
  return {
    lang: i18n.lang
  }
}
export default connect(mapStateToProps)(IndexNavbar);